import { HelpdeskOver } from "../../components/HelpdeskOver";
import { Topics } from "../../utils/constants";
import Layout from "../../components/Layout";
import ReactPlayer from "react-player";

export const Helpdesk = () => {
  return (
    <div className="w-full">
      <Layout active={7} page="Help Desk">
        <div className="md:w-[90%]  w-[692px] mx-auto mt-[36px]">
          <h1 className="text-[32px] text-center font-semibold text-white mb-8">
            Help Desk
          </h1>

          <div className="video-container mb-8">
            <ReactPlayer
            url='./intro.mp4' 
            playing={true} 
            muted={true} 
            controls={true} 
            width="100%" 
            height="auto" 
            config={{
              file: {
                tracks: [
                  {
                    kind: 'subtitles',
                    src: 'subtitles.vtt',
                    srcLang: 'en', 
                    default: true, 
                    label: 'English Subtitles' 
                  }
                ]
              }
            }}
            />
        </div>

          <ul className="flex flex-col gap-4">
            {Topics.map((EachFaq) => (
              <HelpdeskOver EachFaq={EachFaq} />
            ))}
          </ul>
        </div>
      </Layout>
    </div>
  );
};
